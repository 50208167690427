import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Reset from './reset'

const Base = createGlobalStyle`
  html, body {
    font-size: 16px;
    font-family: ${ props => props.theme.fonts.default }, serif;
    height: 100%;
    width: 100%;
    background: ${ props => props.theme.dark.background };
    color: ${ props => props.theme.dark.text };

    &.light-theme,
    .light-theme & {
      background: ${ props => props.theme.light.background };
      color: ${ props => props.theme.light.text };
    }
  }

  * { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    text-decoration: none;
    color: ${ props => props.theme.dark.text };

    .light-theme & {
      color: ${ props => props.theme.light.text };
    }
  }

  body, a, svg {
    cursor: url(${ props => props.theme.dark.cursor.small }) 11 11, auto !important;
    cursor: -webkit-image-set(
      url(${ props => props.theme.dark.cursor.small }) 1x,
      url(${ props => props.theme.dark.cursor.large }) 2x
    ) 11 11, auto !important;

    .light-theme & {
      cursor: url(${ props => props.theme.light.cursor.small }) 11 11, auto !important;
      cursor: -webkit-image-set(
        url(${ props => props.theme.light.cursor.small }) 1x,
        url(${ props => props.theme.light.cursor.large }) 2x
      ) 11 11, auto !important;
    }
  }

  h1 {
    font-family: 'Shapiro-55MiddleText';
    font-size: 3.75rem;
    line-height: 1.183;
    font-weight: normal;

    @media ${ props => props.theme.device.sm } {
      font-size: 1.875rem;
    }
  }

  h2 {
    font-family: 'Shapiro-55Middle';
    font-size: 2.25rem;
    line-height: 0.88;

    @media ${ props => props.theme.device.sm } {
      font-size: 1.125rem;
    }
  }

  p {
    font-size: 2.5rem;
    line-height: 1.25;

    @media ${ props => props.theme.device.sm }  {
      font-size: 1.25rem;
    }
  }

  ::selection {
    background-color: ${ props => props.theme.dark.accent };

    .light-theme & {
      background-color: ${ props => props.theme.light.accent };
    }
  }
`

const GlobalStyles = () => (
  <>
    <Reset/>
    <Base/>
  </>
)

export default GlobalStyles
