import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'
import theme from '../styles/theme'

import Seo from '../components/Seo'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import GlobalStyles from '../styles/GlobalStyles'
import Cookie from '../components/Cookie'

const Wrapper = styled.div`
  width: 100%;
  max-width: ${ props => props.theme.pageWidth };
  margin: 0 auto;
  padding: 0 ${ props => props.theme.pagePadding };

  @media ${ props => props.theme.device.sm } {
    padding: 0 ${ props => props.theme.pagePaddingMobile };
  }
`

// const Layout = props => {
const Layout = ({ children, location, pageContext: { pageName }, isHomepage, isCategory }) => {
  const [colorTheme, setColorTheme] = useState(getColorThemeSetting())
  console.log('layout render, colorTheme:', colorTheme)

  const toggleTheme = useCallback(() => {
    setColorTheme(current => {
      if (current === 'light') {
        window.localStorage.removeItem('colorTheme')
        document.documentElement.classList.remove('light-theme')
        return 'dark'
      } else if (current === 'dark') {
        window.localStorage.setItem('colorTheme', 'light')
        document.documentElement.classList.add('light-theme')
        return 'light'
      }
    })
  }, [])

  return (
    <>
      <Seo location={location.pathname} pageName={pageName}/>
      <CookiesProvider>
        <ThemeProvider theme={{
          ...theme,
          theme: colorTheme,
        }}>
          <>
            <GlobalStyles/>
            <Wrapper>
              <Navigation isHomepage={isHomepage} isCategory={isHomepage} toggleTheme={toggleTheme}/>
              {children}
              <Footer/>
              <Cookie/>
            </Wrapper>
          </>
        </ThemeProvider>
      </CookiesProvider>
    </>
  )
}

function getColorThemeSetting () {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('colorTheme') || 'dark'
  }

  return 'dark'
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
