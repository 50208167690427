import CursorDark1x from '../cursor/cursor-dark-1x.png'
import CursorDark2x from '../cursor/cursor-dark-2x.png'
import CursorLight1x from '../cursor/cursor-light-1x.png'
import CursorLight2x from '../cursor/cursor-light-2x.png'

const theme = {
  fonts: {
    default: 'Shapiro-35FeatherText',
  },
  light: {
    background: '#fff',
    text: '#000',
    accent: '#FFEE00',
    grey: 'rgba(230,230,230,1)',
    cursor: {
      small: CursorLight1x,
      large: CursorLight2x
    },
  },
  dark: {
    background: '#000',
    text: '#fff',
    accent: '#0000FF',
    grey: 'rgba(230,230,230,0.2)',
    cursor: {
      small: CursorDark1x,
      large: CursorDark2x
    },
  },
  pageWidth: '1440px',
  pagePadding: '50px',
  pagePaddingMobile: '30px',
  device: {
    xs: `(max-width: ${ 0 }rem)`,
    sm: `(max-width: ${ 40 }rem)`,
    md: `(max-width: ${ 50 }rem)`,
    lg: `(max-width: ${ 64 }rem)`,
  }
}

export default theme
