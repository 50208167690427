import React, { useState, useCallback } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import LogoSvg from '../images/logo.svg'

import { Squash as Hamburger } from 'hamburger-react'

const Container = styled.nav`
  height: 235px;
  width: 100%;
  padding-top: ${ props => props.theme.pagePadding };
  padding-bottom: ${ props => props.theme.pagePadding };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media ${ props => props.theme.device.sm } {
    height: 114px;
    padding-top: ${ props => props.theme.pagePaddingMobile };
    padding-bottom: ${ props => props.theme.pagePaddingMobile };
  }
`

const Logo = styled(LogoSvg)`
  height: 100%;
  fill: ${ props => props.theme.dark.grey };

  .light-theme & {
    fill: ${ props => props.theme.light.grey };
  }

  .navigation-link-active & {
    fill: ${ props => props.theme.dark.text };

    .light-theme & {
      fill: ${ props => props.theme.light.text };
    }
  }
`

const Link = styled(GatsbyLink)`
  height: 100%;
  font-size: 1.5rem;
  line-height: 1.2;

  &.navigation-link-active {
    text-decoration: underline;
  }

  @media ${ props => props.theme.device.sm } {
    font-size: 2.25rem;
    line-height: 1.39;
    height: auto;
  }
`

const HomeLink = styled(Link)`
  height: 100%;

  @media ${ props => props.theme.device.sm } {
    height: 100%;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: row;

  > a, > div {
    margin-right: 1.25rem;

    &:last-child,
    @media ${ props => props.theme.device.sm } {
      margin-right: 0;
    }
  }

  @media ${ props => props.theme.device.sm } {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    bottom: 0;
    z-index: 5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translate3d(${ props => props.navigationExpanded ? '-100%' : 0 },0,0);
    background-color: ${ props => props.theme.dark.accent };

    .light-theme & {
      background-color: ${ props => props.theme.light.accent };
    }
  }
`

const MenuButton = styled.div`
  display: none;

  @media ${ props => props.theme.device.sm } {
    display: block;
    position: fixed;
    top: 17px;
    right: 21px;
    z-index: 6;
  }

  div {
    transition-property: transform, opacity !important;
  }
`

const ToggleContainer = styled.div`
  margin-top: 1px;
  margin-right: 1.25rem;
  margin-left: auto;
  width: 40px;

  @media ${ props => props.theme.device.sm } {
    position: fixed;
    margin-right: 3.75rem;
    margin-top: 0;
    top: ${ props => props.theme.pagePaddingMobile };
    right: ${ props => props.theme.pagePaddingMobile };
    z-index: 1;
  }
`

const Toggle = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 58%;
  box-sizing: border-box;
  background-color: ${ props => props.theme.dark.text };
  display: flex;
  align-items: center;
  border-radius: 30% 30% 30% 30%/50% 50% 50% 50%;
  position: relative;

  .light-theme & {
    background-color: ${ props => props.theme.light.text };
  }

  &:after {
    content: '';
    display: block;
    width: 58%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.4s ease, background-color 0.4s ease;
    background: ${ props => props.theme.dark.background };
    transform: translate3d(0,0,0);
    border: 3px solid ${ props => props.theme.dark.text };

    .light-theme & {
      background: ${ props => props.theme.light.background };
      transform: translate3d(73%,0,0);
      border: 3px solid ${ props => props.theme.light.text };
    }
  }
`

const ScrollBlocker = createGlobalStyle`
  body {
    overflow: ${ props => props.navigationExpanded && 'hidden' };
  }
`

const Navigation = ({ toggleTheme, isHomepage, isCategory }) => {
  const [navigationExpanded, setNavigationExpanded] = useState(false)

  const toggleNavigation = useCallback(() => {
    setNavigationExpanded(expanded => !expanded)
  }, [])

  const hideNavigation = useCallback(() => {
    setNavigationExpanded(false)
  }, [])

  return (
    <Container>
      <ScrollBlocker navigationExpanded={navigationExpanded}/>
      <HomeLink
        to="/"
        activeClassName="navigation-link-active"
        className={isHomepage && 'navigation-link-active'}
        onClick={hideNavigation}
      >
        <Logo/>
      </HomeLink>
      <ToggleContainer>
        <Toggle onClick={toggleTheme}/>
      </ToggleContainer>
      <MenuButton onClick={toggleNavigation} navigationExpanded={navigationExpanded}>
        <Hamburger size={30}/>
      </MenuButton>
      <Links navigationExpanded={navigationExpanded}>
        <Link
          to="/"
          activeClassName="navigation-link-active"
          className={(isHomepage || isCategory) && 'navigation-link-active'}
          onClick={hideNavigation}
        >
          Work
        </Link>
        <Link
          to="/info"
          activeClassName="navigation-link-active"
          onClick={hideNavigation}
        >
          Info
        </Link>
        <Link
          to="/contact"
          activeClassName="navigation-link-active"
          onClick={hideNavigation}
        >
          Contact
        </Link>
      </Links>
    </Container>
  )
}

export default Navigation
