import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import CloseSvg from '../images/close.svg'

const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: ${ props => props.theme.pagePadding };
  padding-right: ${ props => props.theme.pagePadding };
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${ props => props.theme.dark.accent };

  .light-theme & {
    background: ${ props => props.theme.light.accent };
  }

  @media ${ props => props.theme.device.sm } {
    padding: ${ props => props.theme.pagePaddingMobile };
    height: 100%;
    top: 0;
  }
`

const Text = styled.div`  
  color: ${ props => props.theme.dark.text };

  .light-theme & {
    color: ${ props => props.theme.light.text };
  }

  p {
    font-size: 1.5rem;
    line-height: 1;

    @media ${ props => props.theme.device.sm } {
      /* font-size: 1rem; */
      font-size: 1.375rem;
      line-height: 1.36;
    }
  }

  p:last-child {
    margin-top: 1rem;
  }
`

const Link = styled(GatsbyLink)`
  text-decoration: underline;
`

const CloseButton = styled(CloseSvg)`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;

  .close-stroke {
    stroke: ${ props => props.theme.dark.text };

    .light-theme & {
      stroke: ${ props => props.theme.light.text };
    }
  }
`

function Cookie () {
  const [cookies, setCookie] = useCookies(['consent'])

  if (typeof window === 'undefined') {
    return null
  }

  if (cookies.consent) {
    return null
  }

  return (
    <Container>
      <Text>
        <p>
          We use cookies to make our website optimal for you and to continuously improve it.
        </p>
        <p>
          By continuing to use the website, you consent to the use of cookies.
        </p>
        <p>
          You can find more information about these cookies in our <Link to="/contact#privacy-policy">privacy policy</Link>.
        </p>
      </Text>
      <CloseButton onClick={() => { setCookie('consent', true) }}/>
    </Container>
  )
}

export default Cookie
