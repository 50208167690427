module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: 'B2302', // Navigation and Site Title
  siteTitleAlt: 'B2302', // Alternative Site title for SEO
  siteTitleShort: 'B2302', // short_name for manifest
  siteHeadline: 'B2302', // Headline for schema.org JSONLD
  // siteUrl: 'https://b2302.netlify.com', // Domain of your site. No trailing slash!
  siteUrl: 'http://localhost:8000', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'B2302',
  author: 'b2302', // Author for schema.org JSONLD
  keyword: 'design',
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@cara', // Twitter Username
  ogSiteName: 'B2302', // Facebook Site Name
  ogLanguage: 'en_EN', // Facebook Language
  // googleAnalyticsID: 'UA-47519312-5',

  // Manifest and Progress color
  themeColor: '#6C82FF',
  backgroundColor: '#FFFFFF',
}
